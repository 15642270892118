import React from 'react'

export default function Header(){
    return(  
        <header>
            <h1 className="header-title" id="home">
            Encontre <br/><span>trabalho ideal</span><br/>com facilidade
             </h1> 
        </header>
    )

}