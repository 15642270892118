import React from 'react';

export default function FeaturedCompanies() {
  return (
    <section className="featured" id="companies">
      <h1 className="section-title">Empresas em destaque</h1>
      <p>Conectamos você às melhores oportunidades no mercado, facilitando seu acesso às empresas líderes do setor.</p>
      <div className="featured-wrapper">
        {[
          { name: '', logo: '/img/cf.png' },
          { name: '', logo: '/img/seconstrutora.png' },
          { name: '', logo: '/img/99.png' }
        ].map((company, index) => (
          <div className="featured-card" key={index}>
            <img src={company.logo} alt="" className="featured-image"/>
            <p>{company.name}</p>
            {/* <button className="featured-button">View 2 jobs</button> */}
          </div>
        ))}
      </div>
    </section>
  );
}
