import React, { useState } from 'react';
import Modal from 'react-modal';
import CandidateService from '../../../domain/services/candidate/CandidateService';

Modal.setAppElement('#root'); // Define o elemento root para o modal

export default function JobApplicationForm({ vacancy }) {
  const [errors, setErrors] = useState({});
  const [pdf, setPdf] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPdf(file);
    }
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    birth_date: '',
    experience: '',
    education: '',
    application_status: 'em processo'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataWithFile = new FormData();
    formDataWithFile.append('name', formData.name);
    formDataWithFile.append('email', formData.email);
    formDataWithFile.append('phone', formData.phone);
    formDataWithFile.append('birth_date', formData.birth_date);
    formDataWithFile.append('experience', formData.experience);
    formDataWithFile.append('education', formData.education);
    formDataWithFile.append('vacancy_id', formData.vacancy_id);
    formDataWithFile.append('application_status', formData.application_status);
    formDataWithFile.append('id_vacancy', vacancy.id);

    if (pdf) {
      formDataWithFile.append('resume', pdf);
    }

    try {
      await CandidateService.createCandidate(formDataWithFile);
      setModalMessage('Candidato cadastrado com sucesso!');
      setModalIsOpen(true);
      setFormData({
        name: '',
        email: '',
        phone: '',
        birth_date: '',
        experience: '',
        education: '',
        vacancy_id: '',
        application_status: ''
      });
      setPdf(null);
      setErrors({});
    } catch (error) {
      setModalMessage('Erro ao cadastrar candidato.');
      setModalIsOpen(true);
    }
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      backgroundColor: '#15ab92', // Cor de fundo do modal
      color: '#ffffff',           // Cor do texto no modal
      padding: '20px',
      borderRadius: '8px',
    },
  };

  return (
    <div className="job-application-form">
      <h2>{vacancy.title}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Nome:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            maxLength={80} 
            required
            placeholder="Digite seu nome"
          />
        </div>
        <div className="form-group inline-fields"> {/* Classe para os campos inline */}
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              maxLength={260} 
              required
              placeholder="nome@exemplo.com"
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Telefone:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              maxLength={20} 
              required
              placeholder="Digite seu telefone"
            />
          </div>
        </div>

        <div className="form-group inline-fields"> {/* Classe para os campos inline */}
          <div className="form-group">
          <label htmlFor="birth_date">Nascimento:</label>
          <input
            type="date"
            id="birth_date"
            name="birth_date"
            value={formData.birth_date}
            onChange={handleChange}
            maxLength={10} 
            required
          />
          </div>
          <div className="form-group">
            <label htmlFor="education">Educação:</label>
            <select
              id="education"
              name="education"
              value={formData.education}
              onChange={handleChange}
              
              required
            >
              <option value="" disabled>Selecione a formação</option>
              <option value="Ensino Fundamental Completo">Ensino Fundamental Completo</option>
              <option value="Ensino Médio Completo">Ensino Médio Completo</option>
              <option value="Ensino Superior Completo">Ensino Superior Completo</option>
              <option value="Pós-Graduação">Pós-Graduação</option>
              <option value="Mestrado">Mestrado</option>
              <option value="Doutorado">Doutorado</option>
            </select>
          </div>
        </div>
      
        <div className="form-group">
          <label htmlFor="experience">Experiência:</label>
          <textarea
            id="experience"
            name="experience"
            value={formData.experience}
            onChange={handleChange}
            required
            maxLength={100}  // Limita o campo a 100 caracteres
            rows={4}  
            placeholder="Ex: Cargo, Empresa, Duração (Ex: Analista de Sistemas, Empresa Y, 2 anos"       // Define o número de linhas visíveis
          />
        </div>

        <div className="form-group">
                    <label htmlFor="pdf">Currículos:</label>
                    <input
                    id="pdf"
                    type="file"
                    name="pdf"
                    onChange={handleFileChange}
                    className="form-control"
                    />                                    
        </div>
        <button type="submit" className="job-button">Enviar Candidatura</button>
      </form>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
      >
        <h2>{modalMessage}</h2>
        <button onClick={() => setModalIsOpen(false)} className="job-button">Fechar</button>
      </Modal>

    </div>
  );
}
