import React from 'react'


export default function Blog(){
    return (
        <section className="blog" id="blog">
        <h1 className="section-title">Conselhos de carreira</h1>
        <p>Aprenda mais dicas de carreira do recrutador</p>
        <div className="blog-wrapper">
        <div className="blog-card">
                <img className="blog-image" src="/img/banner2.jpg"/>
                <div className="blog-detail">
                    {/* <span>11 Apr 2022</span> */}
                    <h4>Currículos</h4>
                    <p>Comece com as informações de contato. Inclua seu nome completo, endereço, número de telefone e endereço de e-mail. Escreva um resumo pessoal. Isso deve incluir as suas habilidades, experiências e objetivos de carreira de forma resumida.</p>
                    <hr className="divider"/>
                    {/* <a href="#" className="blog-more">Read</a> */}
                </div>
            </div>
            <div className="blog-card">
                <img className="blog-image" src="/img/bannercentral.jpg"/>
                <div className="blog-detail">
                    {/* <span>11 Apr 2022</span> */}
                    <h4>Como se preparar para entrevistas</h4>
                    <p>Faça uma pesquisa sobre a empresa. Estude a história, missão e valores da empresa, bem como sua estrutura, produtos e serviços. </p>
                    <hr className="divider"/>
                    {/* <a href="#" className="blog-more">Read</a> */}
                </div>
            </div>
            <div className="blog-card">
                <img className="blog-image" src="/img/banner2.jpg"/>
                <div className="blog-detail">
                    {/* <span>11 Apr 2022</span> */}
                    <h4>Fase de contratação</h4>
                    <p>A fase de contratação dos selecionados refere-se ao processo de seleção de funcionários, onde candidatos qualificados são escolhidos para ocupar cargos específicos na empresa ou organização. </p>
                    <hr className="divider"/>
                    {/* <a href="#" className="blog-more">Read</a> */}
                </div>
            </div>            
        </div>
    </section>
    )
}