import React from 'react'
import Nav from '../components/common/Nav'
import Header from '../components/common/Header'
import Search from '../components/common/Search'
import Filter from '../components/common/Filter'
import Footer from '../components/common/Footer'
import JobList from '../components/home/JobList'
import FeaturedCompanies from '../components/home/FeaturedCompanies'
import Testimonial from '../components/home/Testimonial'
import Blog from '../components/home/Blog'
import JoinDetail from '../components/home/JoinDetail'

export default function HomePage(){
    return(
    <>
      <Nav />
      <Header />
      <Search />
      {/* <Filter />  */}
      <JobList />
      <JoinDetail />  
      <FeaturedCompanies /> 
      <Testimonial/>  
      <Blog />
      <Footer/>
    </>
    )
     
}