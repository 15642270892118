import React, { useState } from 'react';
import { Link } from 'react-scroll';

export default function Nav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(prevState => !prevState);
  };

  const handleMenuItemClick = () => {
    if (window.innerWidth <= 768) { 
      setIsMenuOpen(false);
    }
  };

  return (
    <nav className="navbar">
      <h2 className="navbar-logo"><a href="#">LM CONSULTORIA RH</a></h2>
      <div className={`navbar-menu ${isMenuOpen ? 'active' : ''}`}>
        <Link to="home" smooth={true} duration={500}>Home</Link>
        <Link to="jobs" smooth={true} duration={500}>Vagas</Link>
        <Link to="companies" smooth={true} duration={500}>Empresas</Link>       
        <Link to="testimoni" smooth={true} duration={500}>Depoimentos</Link>
        <Link to="contact" smooth={true} duration={500}>Contato</Link>
        {/* <a href="#" smooth={true} duration={500}>Sign In</a> */}
      </div>
      <div className={`menu-toggle ${isMenuOpen ? 'is-active' : ''}`} onClick={handleMenuToggle}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
}
