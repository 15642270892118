import CandidateRepository from '../../repositories/candidate/CandidateRepositoryImpl';

const CandidateService = {
  createCandidate: async ( formDataWithFile) => {
    try {
      const response = await CandidateRepository.createCandidate(formDataWithFile);
      return response;
    } catch (error) {
      console.error('Failed to create candidate:', error);
      throw error;
    }
  }
};

export default CandidateService;
