import React from 'react'


export default function Search(){
    return(
        <div className="search-wrapper">
        <div className="search-box">
            <div className="search-card">
                <input type="text" className="search-input"  placeholder="Título do trabalho ou palavras-chave" />
                <button className="search-button">Buscar</button>
            </div>
        </div>
    </div> 
        
       
                                    
   
    )
}