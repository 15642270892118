import React, { useState, useEffect } from 'react';
import VacancyService from '../../../domain/services/vacancy/VacancyService';
import JobApplicationForm from './JobApplicationForm';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'; // Importa o ícone de fechar

export default function JobList() { 
  const [vacancies, setVacancies] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Estado para armazenar a largura da tela

  useEffect(() => {
    const fetchVacancies = async () => {
      try {
        const data = await VacancyService.getAllVacancies();
        setVacancies(data);
      } catch (error) {
        console.error('Failed to fetch vacancies:', error);
      }
    };

    fetchVacancies();

    // Função para atualizar a largura da tela
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleApply = (job) => {
    setSelectedJob(job);
    setIsModalOpen(true); // Abre o modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Fecha o modal
    setSelectedJob(null); // Limpa o job selecionado
  };

  // Definir os estilos da modal com base na largura da tela
  const modalStyle = {
    content: {
      width: windowWidth <= 700 ? '90%' : '490px',  // Largura menor para telas pequenas
      height: '550px',  // Define a altura da modal
      margin: '20px auto',   // Centraliza a modal
      padding: '8px',  // Padding interno da modal
      borderRadius: '10px',  // Bordas arredondadas
      border: '1px solid #ccc',
      overflow: 'auto', // Permite rolagem se o conteúdo exceder o tamanho
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)' // Fundo com opacidade
    }
  };

  return (
    <section className="job-list" id="jobs">
      {vacancies.length > 0 ? (
        vacancies.map((job, index) => (
          <div className="job-card" key={index}>
            <div className="job-name">
              <div className="job-detail">
                <h4>{job.title}</h4>
                <h3>{job.employment_name}</h3>
                <p>{job.description}</p>
                <p>{job.city_name}</p>             
              </div>              
            </div>
            <div className='job-register'>
              <button className="job-button" onClick={() => handleApply(job)}>Candidate-se</button>
            </div>
          </div>
        ))
      ) : (
        <p>No vacancies available</p>
      )}

      {/* Modal de candidatura */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Formulário de Candidatura"
        ariaHideApp={false}
        className="job-element"
        // style={modalStyle} // Aplicar o estilo dinâmico
      >
        {selectedJob && (
          <div>
            <button onClick={closeModal} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faTimes} style={{ fontSize: '20px', color: '#000' }} />
            </button>
            <JobApplicationForm vacancy={selectedJob} />
          </div>
        )}
      </Modal>
    </section>
  );
}
